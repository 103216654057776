import React from "react";
import { Trans, useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import {
  ChampCost,
  ChampionHeader,
  ChampionHeaderName,
  ChampionHeaderRight,
  ChampionImageFrame,
  ChampionImageImg,
  Type,
  TypeName,
  Types,
} from "@/game-tft/ChampionHeader.style.jsx";
import { cssTierIcon } from "@/game-tft/OverviewChampions.style.jsx";
import StaticTFT from "@/game-tft/static.mjs";
import TypeIcon from "@/game-tft/TypeIcon.jsx";
import { useLocalizedChampions } from "@/game-tft/use-champions.mjs";
import useSetByParam from "@/game-tft/use-set-by-param.mjs";
import HextechMatchGoldIcon from "@/inline-assets/hextech-match-gold.svg";
import Container from "@/shared/ContentContainer.jsx";
import getTierIcon from "@/shared/get-tier-icon-path.mjs";
import { PageDescription } from "@/shared/PageHeader.style";
import { useRouteMetadata } from "@/util/router-hooks.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

const ChampionHeaderComponent = ({
  champion,
  championClasses,
  championOrigins,
  selectedSet,
  tier,
}) => {
  const state = useSnapshot(readState);
  const set = useSetByParam();
  const skinSetting = state.settings.tft.isSkinsEnabled;
  const { t } = useTranslation();
  const localizedChampions = useLocalizedChampions(set);
  const { key, name, cost } = champion;
  const TierIcon = getTierIcon(tier);

  const metadata = useRouteMetadata();

  return (
    <Container className="relative">
      <ChampionHeader className="header-tall">
        <div className="flex mt-10">
          <ChampionImageFrame $cost={cost}>
            <ChampionImageImg
              $championKey={key}
              $set={skinSetting ? selectedSet : null}
            />
            <ChampCost
              className="type-body2"
              color={StaticTFT.getRarityFromTier(cost)}
            >
              <HextechMatchGoldIcon />
              {cost}
            </ChampCost>
          </ChampionImageFrame>
          <ChampionHeaderRight>
            <ChampionHeaderName className="type-h5">
              <div>{localizedChampions?.[key]?.name ?? name}</div>
              <img
                src={TierIcon}
                width="32"
                height="32"
                className={cssTierIcon()}
              />
              <span>
                <Trans i18nKey="tft:forTeamfightTactics">
                  <span>for Teamfight Tactics</span>
                </Trans>
              </span>
            </ChampionHeaderName>
            <Types>
              {championClasses.map(({ name, apiKey }, i) => (
                <Type key={i}>
                  <TypeIcon
                    name={StaticTFT.getTraitKey(apiKey, set)}
                    size={24}
                  />
                  <TypeName className="type-body2">{name}</TypeName>
                </Type>
              ))}
              {championOrigins.map(({ name, apiKey }, i) => (
                <Type key={i}>
                  <TypeIcon
                    name={StaticTFT.getTraitKey(apiKey, set)}
                    size={24}
                  />
                  <TypeName className="type-body2">{name}</TypeName>
                </Type>
              ))}
            </Types>
            {metadata?.subtitle && (
              <PageDescription className="shade4">
                {t(...metadata.subtitle)}
              </PageDescription>
            )}
          </ChampionHeaderRight>
        </div>
      </ChampionHeader>
      {/* <div className="profile-header">
          <SubHeader
            className={`full ${!headerOnScreen ? "scrolled" : "not-scrolled"}`}
          >
            <PageHeaderLinks
              links={[
                {
                  text: t("common:navigation.overview", "Overview"),
                  url: `/tft/champion/${champion.key}/overview`,
                },
                {
                  text: t(
                    "common:navigation.itemsStatistics",
                    "Items Statistics"
                  ),
                  url: `/tft/champion/${champion.key}/items`,
                },
              ]}
            />
          </SubHeader>
        </div> */}
    </Container>
  );
};

export default ChampionHeaderComponent;
